import React, { useState, useRef, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { collection, query, where, getDocs, addDoc, deleteDoc, updateDoc, doc } from "firebase/firestore/lite";
import { auth, db } from "../../firebase";
import { LanguageContext } from "../../context/LanguageContext";
import lang from "../../components/translations";

const LanguageAndAttachment = ({ nextStep, prevStep, languageSkills, personalInfo, handleDataChange }) => {
  const [cv, setCv] = useState(null);
  const [otherDocument, setOtherDocument] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState("");
  const [isAddingLanguage, setIsAddingLanguage] = useState(false);
  const { appLanguage } = useContext(LanguageContext);

  const [newLanguage, setNewLanguage] = useState({
    lId: null,
    name: "Write a Language",
    reading: "Beginner",
    writing: "Beginner",
    speaking: "Beginner",
    listening: "Beginner",
  });

  const [originalData, setOriginalData] = useState(null); // Store the original data

  useEffect(() => {
    if (languageSkills) {
      setOriginalData({ ...languageSkills }); // Store the original data on mount
    }
  }, [languageSkills]);

  const [editingLanguageId, setEditingLanguageId] = useState(null);
  const skillLevels = ["Beginner", "Good", "Very Good", "Fluent"];

  const inputRef = useRef(null);

  const addLanguage = () => {
    if (isAddingLanguage) return; // Prevent adding another language while one is being added
    setIsAddingLanguage(true);

    try {
      // Add new entry with a temporary ID
      const newLanguageEntry = { ...newLanguage, lId: Date.now().toString() };
      handleDataChange([...languageSkills, newLanguageEntry], "languageSkills");

      // Reset editing state
      setEditingLanguageId(newLanguageEntry.lId);
      setNewLanguage(newLanguageEntry);
      // Focus the input field
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, 250);
      setIsAddingLanguage(false);
    } catch (error) {
      console.error("Error adding/updating language: ", error);
    } finally {
      setIsAddingLanguage(false);
    }
  };

  const updateLanguage = (field, value) => {
    setNewLanguage((prevLanguage) => {
      const updatedLanguage = { ...prevLanguage, [field]: value };
      handleDataChange(updatedLanguage, "newLanguage");
      return updatedLanguage;
    });
  };

  const deleteLanguage = (id) => {
    try {
      const updatedLanguageSkills = languageSkills.filter((language) => language.lId !== id);
      handleDataChange(updatedLanguageSkills, "languageSkills");
    } catch (error) {
      console.error("Error deleting language: ", error);
    }
  };

  const saveLanguage = () => {
    if (validate()) {
      try {
        // Update the language in the local languageSkills array
        const updatedLanguageSkills = languageSkills.map((language) =>
          language.lId === editingLanguageId ? { ...language, ...newLanguage } : language
        );

        // Call handleDataChange to update the parent component
        handleDataChange(updatedLanguageSkills, "languageSkills");

        // Clear the editing state and reset the newLanguage form
        setEditingLanguageId(null);
        setNewLanguage({
          lId: null,
          name: "Write a Language",
          reading: "Beginner",
          writing: "Beginner",
          speaking: "Beginner",
          listening: "Beginner",
        });
      } catch (error) {
        console.error("Error saving language:", error);
      }
    }
  };

  const allowedFileTypes = [
    "application/pdf",
    "image/jpeg",
    "image/png",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ];
  const maxFileSize = 5 * 1024 * 1024; // 5 MB

  const validateFile = (file) => {
    if (!allowedFileTypes.includes(file.type)) {
      setError(lang[appLanguage].errorFileInvild);
      return false;
    }

    if (file.size > maxFileSize) {
      setError(lang[appLanguage].errorFileSize);
      return false;
    }

    setError("");
    return true;
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    const file = files[0];

    if (validateFile(file)) {
      if (name === "cv") {
        setCv(file);
      } else if (name === "otherDocument") {
        setOtherDocument(file);
      }
    } else {
      if (name === "cv") {
        setCv(null);
      } else if (name === "otherDocument") {
        setOtherDocument(null);
      }
    }
  };

  const validate = () => {
    let valid = true;

    if (newLanguage.name === "Write a Language") {
      showToast(lang[appLanguage].warnWaitTitle, lang[appLanguage].warmValLangName);
      valid = false;
    } else if (newLanguage.name === "") {
      showToast(lang[appLanguage].warnWaitTitle, lang[appLanguage].warmLangEmpty);
      valid = false;
    }

    return valid;
  };

  const showToast = (header, message, time) => {
    const toastElement = document.getElementById("liveToast");
    const toastHeader = toastElement.querySelector(".me-auto");
    const toastBody = toastElement.querySelector(".toast-body");
    toastHeader.textContent = header;
    toastBody.textContent = message;
    const toast = new window.bootstrap.Toast(toastElement, {
      delay: time ? time : 5000, // one minute
    });
    toast.show();
  };

  const handleSaveAndContinue = async () => {
    if (error) {
      showToast(lang[appLanguage].warnWaitTitle, lang[appLanguage].warnFixErrs);
      return;
    }

    if (!(languageSkills.length > 0)) {
      showToast(lang[appLanguage].warnAddLangTitle, lang[appLanguage].warmAddOneLang);
      return;
    }

    for (let lang of languageSkills) {
      if (!lang.name || lang.name.trim() === "") {
        showToast(lang[appLanguage].errorAddLangTitle, lang[appLanguage].errorAddLangNameEmpty, 10000);
        return;
      }
    }

    if (!personalInfo.cvURL && !cv) {
      showToast(lang[appLanguage].warnWaitTitle, lang[appLanguage].warmAddOneAtch);
      return;
    }

    try {
      setUploading(true);
      const user = auth.currentUser;
      const userEmail = user.email;

      // Query Firebase for the user's personalInfo document
      const personalInfoQuery = query(collection(db, "personalInfo"), where("email", "==", userEmail));
      const querySnapshot = await getDocs(personalInfoQuery);

      if (!querySnapshot.empty) {
        const personalInfoDoc = querySnapshot.docs[0];

        const hasChanged = JSON.stringify(originalData) !== JSON.stringify(languageSkills);

        if (hasChanged) {
          await saveLanguageSkills(personalInfoDoc); // Handle languages
        }
        await saveAttachments(personalInfoDoc); // Handle CV/attachments

        setUploading(false);
        nextStep();
      } else {
        showToast(lang[appLanguage].errorTitle, lang[appLanguage].errorPiNotFond);
        setUploading(false);
      }
    } catch (error) {
      setUploading(false);
    }
  };

  const saveLanguageSkills = async (personalInfoDoc) => {
    try {
      // Fetch current language skills from Firebase to compare with local state
      const languageSnapshot = await getDocs(collection(db, "personalInfo", personalInfoDoc.id, "languageSkills"));
      const firebaseLanguageIds = languageSnapshot.docs.map((doc) => doc.id); // Get all current Firebase document IDs

      // Track local document IDs
      const localLanguageIds = languageSkills.map((entry) => entry.lId).filter(Boolean);

      // Delete any documents in Firebase that are not in the local languageSkills array
      for (const firebaseDocId of firebaseLanguageIds) {
        if (!localLanguageIds.includes(firebaseDocId)) {
          const docRef = doc(db, "personalInfo", personalInfoDoc.id, "languageSkills", firebaseDocId);
          await deleteDoc(docRef); // Delete from Firebase
        }
      }

      // Now, update or add documents to Firebase
      for (const entry of languageSkills) {
        if (entry.lId && firebaseLanguageIds.includes(entry.lId)) {
          // If the document ID exists locally and is also in Firestore, update it
          const languageDocRef = doc(db, "personalInfo", personalInfoDoc.id, "languageSkills", entry.lId);
          await updateDoc(languageDocRef, {
            name: entry.name,
            reading: entry.reading,
            writing: entry.writing,
            speaking: entry.speaking,
            listening: entry.listening,
          });
        } else {
          // If there's no lId (it's a new document), add it
          const newDocRef = await addDoc(collection(db, "personalInfo", personalInfoDoc.id, "languageSkills"), {
            name: entry.name,
            reading: entry.reading,
            writing: entry.writing,
            speaking: entry.speaking,
            listening: entry.listening,
          });

          // Attach the generated Firestore ID to the local entry (but not to Firestore)
          entry.lId = newDocRef.id;
        }
      }
    } catch (error) {
      console.error("Error saving language skills to Firebase:", error);
      throw error; // Re-throw the error to be handled in handleSaveAndContinue
    }
  };

  const saveAttachments = async (personalInfoDoc) => {
    try {
      const updates = {};

      if (cv) {
        const cvURL = await uploadFile(cv, `cv/${personalInfoDoc.id}/${cv.name}`);
        updates.cvURL = cvURL;
        updates.cvName = cv.name;
      }
      if (otherDocument) {
        const otherDocumentURL = await uploadFile(
          otherDocument,
          `otherDocuments/${personalInfoDoc.id}/${otherDocument.name}`
        );
        updates.otherDocumentURL = otherDocumentURL;
        updates.otherDocumentName = otherDocument.name;
      }

      if (cv || otherDocument) {
        const personalInfoDocRef = doc(db, "personalInfo", personalInfoDoc.id);
        await updateDoc(personalInfoDocRef, updates);
      }
    } catch (error) {
      showToast(lang[appLanguage].errorTitle, lang[appLanguage].errorUpldFile + error.message);
      console.log(error);

      throw error;
    }
  };

  const uploadFile = async (file, filePath) => {
    try {
      console.log("File to upload:", file);
      console.log("File path:", filePath);

      if (!filePath) {
        console.error("File path is undefined or null");
        return;
      }

      // Initialize Firebase Storage
      const storage = getStorage();

      // Get a reference to the storage location
      const storageRef = ref(storage, filePath);

      // Upload the file
      await uploadBytes(storageRef, file);

      // Get the download URL
      const downloadURL = await getDownloadURL(storageRef);

      console.log("File uploaded successfully, download URL:", downloadURL);

      return downloadURL;
    } catch (error) {
      console.error("Error uploading file:", error);
      throw error; // Rethrow the error for further handling
    }
  };

  const handleDeleteFile = async (fileFieldURL, fileFieldName) => {
    try {
      const user = auth.currentUser;
      if (user) {
        const userEmail = user.email;
        const personalInfoQuery = query(collection(db, "personalInfo"), where("email", "==", userEmail));
        const querySnapshot = await getDocs(personalInfoQuery);

        if (!querySnapshot.empty) {
          const personalInfoDoc = querySnapshot.docs[0];
          const personalInfoDocRef = doc(db, "personalInfo", personalInfoDoc.id);

          // Update the document in Firebase
          await updateDoc(personalInfoDocRef, {
            [fileFieldURL]: null,
            [fileFieldName]: null,
          });

          // Update the personalInfo in local state using handleDataChange
          const updatedPersonalInfo = {
            ...personalInfo,
            [fileFieldURL]: null,
            [fileFieldName]: null,
          };

          // Call handleDataChange to pass updated personalInfo back to the parent component
          handleDataChange(updatedPersonalInfo, "personalInfo");
        }
      }
    } catch (error) {
      showToast(lang[appLanguage].errorTitle, lang[appLanguage].errorDltFile + error.message);
    }
  };

  return (
    <div className="container">
      <p className="text-muted">{lang[appLanguage].lsaDisc1}</p>
      <p className="text-muted">{lang[appLanguage].lsaDisc2}</p>
      <p className="text-muted">{lang[appLanguage].lsaDisc3}</p>
      <hr />
      <div className="mb-4 mx-0 navbar rounded-2 row shadow">
        <div className="col-12 col-md-6">
          <h4>
            <i className="bi bi-translate me-2"></i> {lang[appLanguage].lsaLangTitle}
          </h4>
        </div>
        <div className="col-12 col-md-6">
          <button
            type="button"
            className="btn btn-light float-end"
            disabled={uploading || error || editingLanguageId !== null}
            onClick={addLanguage}
          >
            <i className="bi bi-plus-circle me-2"></i>
            {lang[appLanguage].btnAddLang}
          </button>
        </div>
      </div>

      <div className="row mb-5">
        <div className="col table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th scope="col" className="text-center">
                  {lang[appLanguage].lsaLangAction}
                </th>
                <th scope="col">{lang[appLanguage].lsaLang}</th>
                <th scope="col" className="text-center">
                  {lang[appLanguage].lsaLangRead}
                </th>
                <th scope="col" className="text-center">
                  {lang[appLanguage].lsaLangWrit}
                </th>
                <th scope="col" className="text-center">
                  {lang[appLanguage].lsaLangSpek}
                </th>
                <th scope="col" className="text-center">
                  {lang[appLanguage].lsaLangLstn}
                </th>
              </tr>
            </thead>
            <tbody>
              {languageSkills.map((language) => (
                <tr key={language.lId} className="fade-in">
                  <td className="text-center">
                    {editingLanguageId === language.lId ? (
                      <button onClick={() => saveLanguage()} className="btn btn-success btn-sm m-1">
                        <i className="bi bi-floppy"></i>
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          setEditingLanguageId(language.lId);
                          setNewLanguage(language); // Load existing data into newLanguage state
                        }}
                        className="btn btn-primary btn-sm m-1"
                        disabled={editingLanguageId !== null}
                      >
                        <i className="bi bi-pencil"></i>
                      </button>
                    )}
                    <button
                      onClick={() => deleteLanguage(language.lId)}
                      className="btn btn-danger btn-sm m-1"
                      disabled={editingLanguageId !== null}
                    >
                      <i className="bi bi-trash3"></i>
                    </button>
                  </td>
                  <th scope="row">
                    {editingLanguageId === language.lId ? (
                      <input
                        type="text"
                        className="form-control"
                        placeholder="ex: Arabic or English ..."
                        value={newLanguage.name}
                        maxLength={50}
                        onChange={(e) => updateLanguage("name", e.target.value)}
                        ref={inputRef}
                      />
                    ) : (
                      language.name
                    )}
                  </th>
                  <td className="text-center">
                    {editingLanguageId === language.lId ? (
                      <select
                        className="form-select"
                        value={newLanguage.reading}
                        onChange={(e) => updateLanguage("reading", e.target.value)}
                      >
                        {skillLevels.map((level) => (
                          <option key={level} value={level}>
                            {level}
                          </option>
                        ))}
                      </select>
                    ) : (
                      language.reading
                    )}
                  </td>
                  <td className="text-center">
                    {editingLanguageId === language.lId ? (
                      <select
                        className="form-select"
                        value={newLanguage.writing}
                        onChange={(e) => updateLanguage("writing", e.target.value)}
                      >
                        {skillLevels.map((level) => (
                          <option key={level} value={level}>
                            {level}
                          </option>
                        ))}
                      </select>
                    ) : (
                      language.writing
                    )}
                  </td>
                  <td className="text-center">
                    {editingLanguageId === language.lId ? (
                      <select
                        className="form-select"
                        value={newLanguage.speaking}
                        onChange={(e) => updateLanguage("speaking", e.target.value)}
                      >
                        {skillLevels.map((level) => (
                          <option key={level} value={level}>
                            {level}
                          </option>
                        ))}
                      </select>
                    ) : (
                      language.speaking
                    )}
                  </td>
                  <td className="text-center">
                    {editingLanguageId === language.lId ? (
                      <select
                        className="form-select"
                        value={newLanguage.listening}
                        onChange={(e) => updateLanguage("listening", e.target.value)}
                      >
                        {skillLevels.map((level) => (
                          <option key={level} value={level}>
                            {level}
                          </option>
                        ))}
                      </select>
                    ) : (
                      language.listening
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="mb-4 mx-0 navbar rounded-2 row shadow">
        <div className="col-12 col-md-6">
          <h4>
            <i className="bi bi-paperclip"></i> {lang[appLanguage].lsaAtchTitle}
          </h4>
        </div>
      </div>

      <div className="row mb-2">
        <div className="col">
          {error && <div className="alert alert-danger">{error}</div>}
          <div className="mb-3">
            <label htmlFor="cvInput" className="form-label">
              {lang[appLanguage].lsaUpldCV}
            </label>
            <input type="file" className="form-control" id="cvInput" name="cv" onChange={handleFileChange} />
          </div>
          {personalInfo.cvURL && (
            <div className="mb-3 fade-in">
              <div className="d-flex align-items-center">
                <a href={personalInfo.cvURL} target="_blank" rel="noopener noreferrer">
                  {personalInfo.cvName || "View CV"}
                </a>
                <span className="ms-2 text-danger cursor-pointer" onClick={() => handleDeleteFile("cvURL", "cvName")}>
                  ⛔
                </span>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-md-6 mt-2">
          <button
            type="button"
            className="btn btn-success button me-1"
            onClick={prevStep}
            disabled={uploading || error || editingLanguageId !== null}
          >
            {lang[appLanguage].btnBack}
          </button>
          <button
            type="button"
            className="btn btn-success button"
            onClick={handleSaveAndContinue}
            disabled={uploading || error || editingLanguageId !== null}
          >
            {uploading ? (
              <>
                <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
                <span role="status"> {lang[appLanguage].lsaUploading}</span>
              </>
            ) : (
              lang[appLanguage].btnSaveCont
            )}{" "}
          </button>
        </div>
      </div>
    </div>
  );
};

LanguageAndAttachment.propTypes = {
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
  personalInfo: PropTypes.object.isRequired,
  languageSkills: PropTypes.array.isRequired,
  handleDataChange: PropTypes.func.isRequired,
};

export default LanguageAndAttachment;
