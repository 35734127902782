import React, { useEffect, useState, useContext } from "react";
import { Routes, Route } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { ThemeProvider } from "react-bootstrap";
import { LanguageContext } from "./context/LanguageContext";
import "./App.css";
import JobDescription from "./components/JobDescription/JobDescription";
import Wizard from "./Wizard/Wizard";
import Navbar from "./components/Navbar/Navbar.js";
import Footer from "./components/Footer/Footer.js";
import VerificationPage from "./Auth/VerificationPage.js";
import PrivateRoute from "./components/PrivateRoute";
import { auth } from "./firebase.js";

function App() {
  const [user, setUser] = useState(null);
  const { appLanguage } = useContext(LanguageContext);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const htmlTag = document.documentElement;
    if (appLanguage === "ar") {
      htmlTag.setAttribute("lang", "ar");
      htmlTag.setAttribute("dir", "rtl");

      // Load RTL Bootstrap CSS
      const link = document.createElement("link");
      link.rel = "stylesheet";
      link.href = "https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.rtl.min.css";
      document.head.appendChild(link);

      // Optionally remove the LTR CSS if dynamically loaded
      const ltrLinks = document.querySelectorAll(
        "link[href='https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css']"
      );
      ltrLinks.forEach((link) => {
        document.head.removeChild(link);
      });
    } else {
      htmlTag.setAttribute("lang", "en");
      htmlTag.setAttribute("dir", "ltr");

      // Load LTR Bootstrap CSS
      const link = document.createElement("link");
      link.rel = "stylesheet";
      link.href = "https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css";
      document.head.appendChild(link);

      // Optionally remove the RTL CSS if dynamically loaded
      const rtlLinks = document.querySelectorAll(
        "link[href='https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.rtl.min.css']"
      );
      rtlLinks.forEach((link) => {
        document.head.removeChild(link);
      });
    }
  }, [appLanguage]);

  return (
    <ThemeProvider dir={appLanguage === "ar" ? "rtl" : "ltr"}>
      <div className="app-container">
        <Navbar user={user} />
        <div className="main-content">
          <Routes>
            <Route path="/" element={<JobDescription />} />
            <Route path="/verification" element={<VerificationPage />} />
            <Route path="/wizard" element={<PrivateRoute element={Wizard} />} />
          </Routes>
        </div>
        <hr className="border border-success border-3 opacity-75" />
        <Footer />
      </div>
    </ThemeProvider>
  );
}

export default App;
