import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import StrictModeDroppable from "./StrictModeDroppable"; // Import the custom component
import { db, auth } from "../../firebase";
import { collection, addDoc, doc, getDocs, query, where, updateDoc, deleteDoc } from "firebase/firestore/lite";
import InputMask from "react-input-mask";
import { LanguageContext } from "../../context/LanguageContext";
import lang from "../../components/translations";

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  background: isDragging ? "palegreen" : "",
  ...draggableStyle,
});

const Experience = ({ nextStep, prevStep, experienceHistory, handleDataChange }) => {
  const { appLanguage } = useContext(LanguageContext);

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [scrollToForm, setScrollToForm] = useState(false);
  const formRef = React.createRef();
  const [editItemId, setEditItemId] = useState(null);
  const [fadeOutForCancelForm, setFadeOutForCancelForm] = useState(false);
  const [newEntry, setNewEntry] = useState({
    positionName: "",
    companyName: "",
    companyAddress: "",
    startingSalary: 0,
    endingSalary: 0,
    currency: "USD", // Default currency
    startDate: "",
    endDate: "",
    stillWorking: false,
    fieldOfWork: "",
    typeOfJob: "",
    managerName: "",
    managerTitle: "",
    managerNumber: "",
    arrangeCall: false,
    performanceRating: "",
    mostLiked: "",
    leastLiked: "",
    leaveReason: "",
    leaveExplanation: "",
  });
  const [originalData, setOriginalData] = useState(null); // Store the original data

  useEffect(() => {
    if (experienceHistory) {
      setOriginalData({ ...experienceHistory }); // Store the original data on mount
    }
  }, [experienceHistory]);

  useEffect(() => {
    if (scrollToForm && formRef.current) {
      window.scrollTo({ top: formRef.current.offsetTop, behavior: "smooth" });
      setScrollToForm(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollToForm, showForm]);

  const [isReferenceCallConfirmed, setIsReferenceCallConfirmed] = useState(false);

  useEffect(() => {
    setShowForm(true);
  }, []);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    // Reorder the items in the experience history
    const reorderedItems = reorder(experienceHistory, result.source.index, result.destination.index);

    // Use handleDataChange to update the reordered items in the parent (Wizard) component
    handleDataChange(reorderedItems, "experienceHistory");
  };

  const showToast = (header, message, time) => {
    const toastElement = document.getElementById("liveToast");
    const toastHeader = toastElement.querySelector(".me-auto");
    const toastBody = toastElement.querySelector(".toast-body");
    toastHeader.textContent = header;
    toastBody.textContent = message;
    const toast = new window.bootstrap.Toast(toastElement, {
      delay: time == null ? 5000 : time,
    });
    toast.show();
  };

  const handleAddOrUpdate = async (e) => {
    e.preventDefault();

    // If already submitting, return early to prevent multiple submissions
    if (isSubmitting) return;

    setIsSubmitting(true);

    if (!isReferenceCallConfirmed) {
      showToast(lang[appLanguage].eduGradYear, lang[appLanguage].warnCnfrmRefCall);
      setIsSubmitting(false);
      return;
    }

    if (validate()) {
      try {
        let updatedExperienceHistory;
        if (editItemId) {
          // Update existing entry
          updatedExperienceHistory = experienceHistory.map((item) =>
            item.expId === editItemId ? { ...item, ...newEntry } : item
          );
        } else {
          // Add new entry
          updatedExperienceHistory = [...experienceHistory, { ...newEntry, expId: Date.now().toString() }];
        }

        // Pass the updated history back to Wizard
        handleDataChange(updatedExperienceHistory, "experienceHistory");

        showToast(lang[appLanguage].successExpAdded, lang[appLanguage].successMorExpBetr, 10000);

        setShowForm(false);
        setEditItemId(null);
        setNewEntry({
          positionName: "",
          companyName: "",
          companyAddress: "",
          startingSalary: 0,
          endingSalary: 0,
          currency: "USD",
          startDate: "",
          endDate: "",
          stillWorking: false,
          fieldOfWork: "",
          typeOfJob: "",
          managerName: "",
          managerTitle: "",
          managerNumber: "",
          arrangeCall: false,
          performanceRating: "",
          mostLiked: "",
          leastLiked: "",
          leaveReason: "",
          leaveExplanation: "",
        });
        setIsReferenceCallConfirmed(false);
        setShowForm(true);
      } catch (error) {
        console.error("Error adding/updating experience history: ", error);
      } finally {
        setIsSubmitting(false);
      }
    } else {
      setIsSubmitting(false);
    }
  };

  const handleEdit = (item) => {
    setNewEntry(item);
    setEditItemId(item.expId);
    setShowForm(true);
    setScrollToForm(true);
  };

  const handleDelete = async (expId) => {
    const updatedExperienceHistory = experienceHistory.filter((item) => item.expId !== expId);
    handleDataChange(updatedExperienceHistory, "experienceHistory");
  };

  const handleCancel = () => {
    setFadeOutForCancelForm(true);
    setTimeout(() => {
      setShowForm(false);
      setFadeOutForCancelForm(false);
    }, 300);

    setEditItemId(null);
    setNewEntry({
      positionName: "",
      companyName: "",
      companyAddress: "",
      startingSalary: 0,
      endingSalary: 0,
      currency: "USD",
      startDate: "",
      endDate: "",
      stillWorking: false,
      fieldOfWork: "",
      typeOfJob: "",
      managerName: "",
      managerTitle: "",
      managerNumber: "",
      arrangeCall: false,
      performanceRating: "",
      mostLiked: "",
      leastLiked: "",
      leaveReason: "",
      leaveExplanation: "",
    });
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    // Convert radio button values from string to boolean
    const newValue =
      type === "checkbox"
        ? checked
        : name === "arrangeCall"
          ? value === "true"
          : type === "number"
            ? Number(value)
            : value;

    setNewEntry({
      ...newEntry,
      [name]: newValue,
    });

    if (name === "managerNumber") {
      handlePhoneValidation(newValue);
    }
  };

  const validate = () => {
    let tempErrors = {};
    let valid = true;

    if (!newEntry.companyName || newEntry.companyName === "") {
      tempErrors.companyName = lang[appLanguage].valBlankField;
      valid = false;
    }

    if (!newEntry.fieldOfWork || newEntry.fieldOfWork === "") {
      tempErrors.fieldOfWork = lang[appLanguage].valBlankField;
      valid = false;
    }

    if (!newEntry.managerName || newEntry.managerName === "") {
      tempErrors.managerName = lang[appLanguage].valBlankField;
      valid = false;
    }

    if (!newEntry.managerTitle || newEntry.managerTitle === "") {
      tempErrors.managerTitle = lang[appLanguage].valBlankField;
      valid = false;
    }

    if (!newEntry.positionName || newEntry.positionName === "") {
      tempErrors.positionName = lang[appLanguage].valBlankField;
      valid = false;
    }

    if (new Date(newEntry.startDate) > new Date()) {
      tempErrors.startDate = lang[appLanguage].expValStrtDate;
      valid = false;
    }

    if (!newEntry.startDate || newEntry.startDate === "") {
      tempErrors.startDate = lang[appLanguage].valBlankField;
      valid = false;
    }

    if (!newEntry.performanceRating || newEntry.performanceRating === "") {
      tempErrors.performanceRating = lang[appLanguage].expValPrfRate;
      valid = false;
    }

    if ((!newEntry.endDate || newEntry.endDate === "") && !newEntry.stillWorking) {
      tempErrors.endDate = lang[appLanguage].valBlankField;
      valid = false;
    }

    if (!newEntry.typeOfJob || newEntry.typeOfJob === "") {
      tempErrors.typeOfJob = lang[appLanguage].valBlankField;
      valid = false;
    }

    if (!newEntry.startingSalary || newEntry.startingSalary === "") {
      tempErrors.startingSalary = lang[appLanguage].valBlankField;
      valid = false;
    }

    if (newEntry.startingSalary < 1 || newEntry.startingSalary > 999999) {
      tempErrors.startingSalary = lang[appLanguage].expValStrtSlry;
      valid = false;
    }

    if (!newEntry.endingSalary || newEntry.endingSalary === "") {
      tempErrors.endingSalary = lang[appLanguage].valBlankField;
      valid = false;
    }

    if (newEntry.endingSalary < newEntry.startingSalary || newEntry.endingSalary > 999999) {
      tempErrors.endingSalary =
        appLanguage === "en"
          ? `Ending Salary must be between ${newEntry.startingSalary} and 999999!`
          : `الراتب النهائي يجب أن يكون بين ${newEntry.startingSalary} و 999999!`;
      valid = false;
    }

    const phoneRegex = /^\(\+\d{3}\) \d{3} \d{3} \d{2} \d{2}$/;
    if (newEntry.managerNumber && !phoneRegex.test(newEntry.managerNumber)) {
      tempErrors.managerNumber = lang[appLanguage].valPhone;
      valid = false;
    }

    if (!newEntry.leaveReason) {
      tempErrors.leaveReason = lang[appLanguage].expValLevResn;
      valid = false;
    }

    if (!newEntry.leaveExplanation || newEntry.leaveExplanation === "") {
      tempErrors.leaveExplanation = lang[appLanguage].expValLevExpln;
      valid = false;
    }

    setErrors(tempErrors);
    return valid;
  };

  const handlePhoneValidation = (value) => {
    // Ensure the value matches the expected phone number pattern
    const phoneRegex = /^\(\+\d{3}\) \d{3} \d{3} \d{2} \d{2}$/;

    if (value && value !== "(+___) ___ ___ __ __" && !phoneRegex.test(value)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        managerNumber: lang[appLanguage].valPhone,
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        managerNumber: undefined, // Clear the error if valid
      }));
    }
  };

  const handleSaveAndContinue = async () => {
    const hasChanged = JSON.stringify(originalData) !== JSON.stringify(experienceHistory);

    if (!hasChanged) {
      nextStep();
      return;
    }

    try {
      const user = auth.currentUser;
      const userEmail = user.email;

      // Query Firebase for the user's personalInfo document
      const personalInfoQuery = query(collection(db, "personalInfo"), where("email", "==", userEmail));
      const querySnapshot = await getDocs(personalInfoQuery);

      if (!querySnapshot.empty) {
        const personalInfoDoc = querySnapshot.docs[0];

        // Fetch current experience history from Firebase to compare with local state
        const experienceSnapshot = await getDocs(
          collection(db, "personalInfo", personalInfoDoc.id, "experienceHistory")
        );
        const firebaseExperienceIds = experienceSnapshot.docs.map((doc) => doc.id); // Get all current Firebase document IDs

        // Track local document IDs
        const localExperienceIds = experienceHistory.map((entry) => entry.firebaseDocId).filter(Boolean);

        // Delete any documents in Firebase that are not in the local experienceHistory array
        for (const firebaseDocId of firebaseExperienceIds) {
          if (!localExperienceIds.includes(firebaseDocId)) {
            const docRef = doc(db, "personalInfo", personalInfoDoc.id, "experienceHistory", firebaseDocId);
            await deleteDoc(docRef); // Delete from Firebase
          }
        }

        // Now, update or add documents to Firebase
        for (const entry of experienceHistory) {
          if (entry.firebaseDocId) {
            // If the document ID exists locally, update the document in Firestore
            const experienceDocRef = doc(
              db,
              "personalInfo",
              personalInfoDoc.id,
              "experienceHistory",
              entry.firebaseDocId
            );
            await updateDoc(experienceDocRef, {
              positionName: entry.positionName,
              companyName: entry.companyName,
              companyAddress: entry.companyAddress,
              startingSalary: entry.startingSalary,
              endingSalary: entry.endingSalary,
              currency: entry.currency,
              startDate: entry.startDate,
              endDate: entry.endDate,
              stillWorking: entry.stillWorking,
              fieldOfWork: entry.fieldOfWork,
              typeOfJob: entry.typeOfJob,
              managerName: entry.managerName,
              managerTitle: entry.managerTitle,
              managerNumber: entry.managerNumber,
              arrangeCall: entry.arrangeCall,
              performanceRating: entry.performanceRating,
              mostLiked: entry.mostLiked,
              leastLiked: entry.leastLiked,
              leaveReason: entry.leaveReason,
              leaveExplanation: entry.leaveExplanation,
              // Do NOT store firebaseDocId in Firestore
            });
          } else {
            // If there's no firebaseDocId, add a new document
            const newDocRef = await addDoc(collection(db, "personalInfo", personalInfoDoc.id, "experienceHistory"), {
              positionName: entry.positionName,
              companyName: entry.companyName,
              companyAddress: entry.companyAddress,
              startingSalary: entry.startingSalary,
              endingSalary: entry.endingSalary,
              currency: entry.currency,
              startDate: entry.startDate,
              endDate: entry.endDate,
              stillWorking: entry.stillWorking,
              fieldOfWork: entry.fieldOfWork,
              typeOfJob: entry.typeOfJob,
              managerName: entry.managerName,
              managerTitle: entry.managerTitle,
              managerNumber: entry.managerNumber,
              arrangeCall: entry.arrangeCall,
              performanceRating: entry.performanceRating,
              mostLiked: entry.mostLiked,
              leastLiked: entry.leastLiked,
              leaveReason: entry.leaveReason,
              leaveExplanation: entry.leaveExplanation,
              // Do NOT store firebaseDocId in Firestore
            });

            // Store the generated Firestore document ID locally for future updates/deletions
            entry.firebaseDocId = newDocRef.id;
          }
        }

        setLoading(false);

        // After saving to Firebase, move to the next step
        nextStep();
      } else {
        console.error("No personalInfo document found for the user");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error saving experience data to Firebase before continuing:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const forms = document.querySelectorAll(".needs-validation");
    Array.from(forms).forEach((form) => {
      form.addEventListener(
        "submit",
        (event) => {
          if (!form.checkValidity()) {
            event.preventDefault();
          }
          form.classList.add("was-validated");
        },
        false
      );
    });
  }, []);

  return (
    <div className="container">
      <p className="text-muted">{lang[appLanguage].expDisc1}</p>
      <p className="text-muted">{lang[appLanguage].expDisc2}</p>

      <p className="fw-medium text-muted">{lang[appLanguage].expDisc3}</p>

      <p className="fs-5 fw-medium text-muted text-center">{lang[appLanguage].expDisc4}</p>
      <hr />
      <div className="mb-4 mx-0 navbar rounded-2 row shadow">
        <div className="col-12 col-md-6">
          <h4>
            <i className="bi bi-briefcase-fill"></i> {lang[appLanguage].expTitle}
          </h4>
        </div>
        <div className="col-12 col-md-6">
          <button
            type="button"
            className="btn btn-light float-end"
            onClick={() => setShowForm(true)}
            disabled={showForm}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              className="bi bi-plus-circle me-2"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
              <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
            </svg>
            {lang[appLanguage].btnAddExpHitry}
          </button>
        </div>
      </div>

      {(showForm || fadeOutForCancelForm) && (
        <div ref={formRef} className={`row col-12  ${fadeOutForCancelForm ? "fade-out" : "fade-in"}`}>
          <div className="col">
            <div className="card m-3 mx-auto rounded-3 shadow">
              <div className="card-body">
                <form onSubmit={handleAddOrUpdate} className="needs-validation" noValidate>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className="mb-3">
                        <label className="form-label">{lang[appLanguage].expCpnyName}</label>
                        <input
                          type="text"
                          name="companyName"
                          className={`form-control ${errors.companyName ? "is-invalid" : ""}`}
                          value={newEntry.companyName}
                          required
                          onChange={handleChange}
                          maxLength={200}
                        />
                        <div className="invalid-feedback">{errors.companyName}</div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="mb-3">
                        <label className="form-label">{lang[appLanguage].expCpnyAdrs}</label>
                        <input
                          type="text"
                          name="companyAddress"
                          className={`form-control ${errors.companyAddress ? "is-invalid" : ""}`}
                          value={newEntry.companyAddress}
                          onChange={handleChange}
                          maxLength={255}
                        />
                        <div className="invalid-feedback">{errors.companyAddress}</div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className="mb-3">
                        <label className="form-label">{lang[appLanguage].expWorkField}</label>
                        <input
                          type="text"
                          name="fieldOfWork"
                          placeholder="Ex: Medical"
                          className={`form-control ${errors.fieldOfWork ? "is-invalid" : ""}`}
                          value={newEntry.fieldOfWork}
                          onChange={handleChange}
                          maxLength={200}
                        />
                        <div className="invalid-feedback">{errors.fieldOfWork}</div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="mb-3">
                        <label className="form-label">{lang[appLanguage].expPosTitle}</label>
                        <input
                          type="text"
                          name="positionName"
                          placeholder="Ex: Medical Representative"
                          className={`form-control ${errors.positionName ? "is-invalid" : ""}`}
                          value={newEntry.positionName}
                          required
                          maxLength={200}
                          onChange={handleChange}
                        />
                        <div className="invalid-feedback">{errors.positionName}</div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className="mb-3">
                        <label className="form-label">{lang[appLanguage].expStrtDate}</label>
                        <input
                          name="startDate"
                          type="date"
                          max={new Date().toISOString().split("T")[0]}
                          className={`form-control ${errors.startDate ? "is-invalid" : ""}`}
                          value={newEntry.startDate}
                          required
                          onChange={handleChange}
                        />
                        <div className="invalid-feedback">{errors.startDate}</div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="mb-3">
                        <label className="form-label">{lang[appLanguage].expEndDate}</label>
                        <input
                          type="date"
                          name="endDate"
                          className={`form-control ${errors.endDate ? "is-invalid" : ""}`}
                          value={newEntry.stillWorking ? "" : newEntry.endDate}
                          onChange={handleChange}
                          required={!newEntry.stillWorking}
                          disabled={newEntry.stillWorking} // Disable the input if still working
                          min={
                            newEntry.startDate
                              ? new Date(new Date(newEntry.startDate).getTime() + 86400000).toISOString().split("T")[0]
                              : ""
                          }
                          max={new Date().toISOString().split("T")[0]}
                        />
                        <div className="invalid-feedback">{errors.endDate}</div>
                        <div className="form-check mt-2">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="stillWorking"
                            checked={newEntry.stillWorking}
                            onChange={handleChange}
                            id="stillWorking"
                          />
                          <label className="form-check-label" htmlFor="stillWorking">
                            {lang[appLanguage].expCrntlyWork}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className="mb-3">
                        <label className="form-label">{lang[appLanguage].expWorkType}</label>
                        <select
                          className={`form-select ${errors.typeOfJob ? "is-invalid" : ""}`}
                          value={newEntry.typeOfJob}
                          name="typeOfJob"
                          onChange={handleChange}
                          required
                        >
                          <option value="" disabled>
                            Select...
                          </option>
                          <option value="Full-time">Full-time</option>
                          <option value="Part-time">Part-time</option>
                          <option value="Temporary">Temporary</option>
                          <option value="Internship">Internship</option>
                          <option value="Freelance">Freelance</option>
                          <option value="Seasonal">Seasonal</option>
                          <option value="Volunteer">Volunteer</option>
                          <option value="Per Diem">Per Diem</option>
                          <option value="Rotational">Rotational</option>
                          <option value="On-call">On-call</option>
                        </select>
                        <div className="invalid-feedback">{errors.typeOfJob}</div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className="mb-3">
                        <label className="form-label">{lang[appLanguage].expSlryStrt}</label>
                        <div className="input-group mb-3">
                          <input
                            type="number"
                            className={`form-control ${errors.startingSalary ? "is-invalid" : ""}`}
                            value={newEntry.startingSalary}
                            onChange={handleChange}
                            name="startingSalary"
                            required
                            min={1}
                            max={999999}
                          />
                          <div className="invalid-feedback">{errors.startingSalary}</div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="mb-3">
                        <label className="form-label">{lang[appLanguage].expSlryEnd}</label>
                        <div className="input-group mb-3">
                          <input
                            type="number"
                            className={`form-control ${errors.endingSalary ? "is-invalid" : ""}`}
                            value={newEntry.endingSalary}
                            onChange={handleChange}
                            name="endingSalary"
                            required
                            min={newEntry.startingSalary}
                            max={999999}
                          />
                          <div className="invalid-feedback">{errors.endingSalary}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className="mb-3">
                        <label className="form-label">{lang[appLanguage].expMngrName}</label>
                        <input
                          type="text"
                          className={`form-control ${errors.managerName ? "is-invalid" : ""}`}
                          value={newEntry.managerName}
                          onChange={handleChange}
                          required
                          maxLength={200}
                          name="managerName"
                        />
                        <div className="invalid-feedback">{errors.managerName}</div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="mb-3">
                        <label className="form-label">{lang[appLanguage].expMngrTitle}</label>
                        <input
                          type="text"
                          required
                          className={`form-control ${errors.managerTitle ? "is-invalid" : ""}`}
                          value={newEntry.managerTitle}
                          onChange={handleChange}
                          name="managerTitle"
                          maxLength={200}
                        />
                        <div className="invalid-feedback">{errors.managerTitle}</div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="mb-3">
                        <label className="form-label">{lang[appLanguage].expMngrPhon}</label>
                        <InputMask
                          mask="(+999) 999 999 99 99"
                          value={newEntry.managerNumber}
                          onChange={handleChange}
                          id="managerNumber"
                          name="managerNumber"
                          className={`form-control`}
                        />
                        <div className="invalid-feedback">{errors.managerNumber}</div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="mb-3">
                        <label className="form-label form-range">{lang[appLanguage].expArngCall}</label>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="arrangeCall"
                            value="true"
                            checked={newEntry.arrangeCall === true}
                            onChange={handleChange}
                            id="arrangeCallYes"
                          />
                          <label className="form-check-label" htmlFor="arrangeCallYes">
                            {lang[appLanguage].expOptionYes}
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="arrangeCall"
                            value="false"
                            checked={newEntry.arrangeCall === false}
                            onChange={handleChange}
                            id="arrangeCallNo"
                          />
                          <label className="form-check-label" htmlFor="arrangeCallNo">
                            {lang[appLanguage].expOptionNo}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className="mb-3">
                        <label className="form-label ">{lang[appLanguage].expMngrRate}</label>
                        <select
                          className={`form-select ${errors.performanceRating ? "is-invalid" : ""}`}
                          value={newEntry.performanceRating}
                          onChange={handleChange}
                          required
                          name="performanceRating"
                        >
                          <option value="" disabled>
                            Select...
                          </option>
                          <option value="Excellent">Excellent</option>
                          <option value="Very Good">Very Good</option>
                          <option value="Good">Good</option>
                          <option value="Average">Average</option>
                          <option value="Below Average">Below Average</option>
                        </select>
                        <div className="invalid-feedback">{errors.performanceRating}</div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="mb-3">
                        <label className="form-label">{lang[appLanguage].expMostLike}</label>
                        <textarea
                          className={`form-control ${errors.mostLiked ? "is-invalid" : ""}`}
                          value={newEntry.mostLiked}
                          onChange={handleChange}
                          maxLength={255}
                          name="mostLiked"
                        ></textarea>
                        <div className="invalid-feedback">{errors.mostLiked}</div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className="mb-3">
                        <label className="form-label">{lang[appLanguage].expLstEnjy}</label>
                        <textarea
                          className={`form-control ${errors.leastLiked ? "is-invalid" : ""}`}
                          value={newEntry.leastLiked}
                          name="leastLiked"
                          maxLength={255}
                          onChange={handleChange}
                        ></textarea>
                        <div className="invalid-feedback">{errors.leastLiked}</div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="mb-3">
                        <label className="form-label">{lang[appLanguage].expLevRsn}</label>
                        <select
                          className={`form-select ${errors.leaveReason ? "is-invalid" : ""}`}
                          value={newEntry.leaveReason}
                          onChange={handleChange}
                          name="leaveReason"
                          required
                        >
                          <option value="" disabled>
                            Select...
                          </option>
                          <option value="My Choice">My Choice</option>
                          <option value="Promoted">Promoted</option>
                          <option value="Lateral Move">Lateral Move</option>
                          <option value="Mutual Decision">Mutual Decision</option>
                          <option value="Terminated for Cause">Terminated for Cause</option>
                          <option value="Not Leaving">Not Leaving</option>
                          <option value="Layoff">Layoff</option>
                          <option value="Buyout">Buyout</option>
                          <option value="Other Circumstances">Other Circumstances</option>
                        </select>
                        <div className="invalid-feedback">{errors.leaveReason}</div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="mb-3">
                        <label className="form-label">{lang[appLanguage].expLevExpln}</label>
                        <textarea
                          className={`form-control ${errors.leaveExplanation ? "is-invalid" : ""}`}
                          value={newEntry.leaveExplanation}
                          onChange={handleChange}
                          name="leaveExplanation"
                          maxLength={255}
                          required
                        ></textarea>
                        <div className="invalid-feedback">{errors.leaveExplanation}</div>
                      </div>
                    </div>
                  </div>
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="referenceCallConfirm"
                      name="referenceCallConfirm"
                      checked={isReferenceCallConfirmed}
                      onChange={(e) => {
                        setIsReferenceCallConfirmed(e.target.checked);
                      }}
                    />
                    <label className="form-check-label" htmlFor="referenceCallConfirm">
                      {lang[appLanguage].expNoteRefCal}
                    </label>
                  </div>
                  <div className="row">
                    <div className="col">
                      <button type="submit" className="btn btn-primary">
                        {isSubmitting ? (
                          <div className="spinner-border text-light" role="status">
                            <span className="px-1 status"> {lang[appLanguage].loading}</span>
                          </div>
                        ) : editItemId ? (
                          lang[appLanguage].btnUpdate
                        ) : (
                          lang[appLanguage].btnAdd
                        )}
                      </button>
                    </div>
                    <div className="col text-end">
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => {
                          handleCancel();
                          setFadeOutForCancelForm(true);
                        }}
                      >
                        {experienceHistory.length > 0
                          ? appLanguage === "en"
                            ? "I only had " + experienceHistory.length + " Job"
                            : "لقد كان لدي " + experienceHistory.length + " وظيفة فقط"
                          : appLanguage === "en"
                            ? "I do not have any experience"
                            : "ليس لدي أي خبرة"}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}

      <form>
        <div className="row mt-2">
          <DragDropContext onDragEnd={onDragEnd}>
            <StrictModeDroppable droppableId="experienceHistory">
              {(provided, snapshot) => (
                <div id="accordionList" className="col accordion" {...provided.droppableProps} ref={provided.innerRef}>
                  {experienceHistory.map((item, index) => (
                    <Draggable key={item.expId} draggableId={item.expId} index={index}>
                      {(provided, snapshot) => (
                        <div
                          className="accordion-item"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                        >
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapse${item.expId}`}
                            aria-expanded="false"
                            aria-controls={`collapse${item.expId}`}
                          >
                            {item.positionName}
                            &nbsp;-&nbsp;
                            <span className="text-body-secondary">{item.companyName}</span>
                          </button>
                          <div
                            id={`collapse${item.expId}`}
                            className="accordion-collapse collapse"
                            data-bs-parent="#accordionList"
                          >
                            <div className="accordion-body">
                              <div className="row mb-2">
                                <div className="col">
                                  <button
                                    type="button"
                                    className="btn btn-danger btn-sm float-end"
                                    onClick={() => handleDelete(item.expId)}
                                  >
                                    <i className="bi bi-trash3"></i>
                                  </button>
                                  <button
                                    type="button"
                                    className="btn me-2 btn-success button btn-sm float-end"
                                    onClick={() => handleEdit(item)}
                                  >
                                    <i className="bi bi-pencil"></i>
                                  </button>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-12 col-md-6 my-1">
                                  <div className="form-floating">
                                    <input
                                      disabled
                                      readOnly
                                      type="text"
                                      className="form-control"
                                      id="positionName"
                                      value={item.positionName}
                                    />
                                    <label htmlFor="positionName">{lang[appLanguage].expPosTitle}</label>
                                  </div>
                                </div>
                                <div className="col-12 col-md-6 my-1">
                                  <div className="form-floating">
                                    <input
                                      disabled
                                      readOnly
                                      type="text"
                                      className="form-control"
                                      id="companyName"
                                      value={item.companyName}
                                    />
                                    <label htmlFor="companyName">{lang[appLanguage].expCpnyName}</label>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-12 col-md-6 my-1">
                                  <div className="form-floating">
                                    <input
                                      disabled
                                      readOnly
                                      type="text"
                                      className="form-control"
                                      id="fieldOfWork"
                                      value={item.fieldOfWork}
                                    />
                                    <label htmlFor="fieldOfWork">{lang[appLanguage].expDisc1}</label>
                                  </div>
                                </div>
                                <div className="col-12 col-md-6 my-1">
                                  <div className="form-floating">
                                    <input
                                      disabled
                                      readOnly
                                      type="text"
                                      className="form-control"
                                      id="companyAddress"
                                      value={item.companyAddress}
                                    />
                                    <label htmlFor="companyAddress">{lang[appLanguage].expWorkField}</label>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-12 col-md-6 my-1">
                                  <div className="form-floating">
                                    <input
                                      disabled
                                      readOnly
                                      type="text"
                                      className="form-control"
                                      id="startDate"
                                      value={item.startDate}
                                    />
                                    <label htmlFor="startDate">{lang[appLanguage].expStrtDate}</label>
                                  </div>
                                </div>
                                <div className="col-12 col-md-6 my-1">
                                  <div className="form-floating">
                                    <input
                                      disabled
                                      readOnly
                                      type="text"
                                      className="form-control"
                                      id="endDate"
                                      value={item.stillWorking ? "Currently Working" : item.endDate}
                                    />
                                    <label htmlFor="endDate">{lang[appLanguage].expEndDate}</label>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-12 col-md-6 my-1">
                                  <div className="form-floating">
                                    <input
                                      disabled
                                      readOnly
                                      type="text"
                                      className="form-control"
                                      id="typeOfJob"
                                      value={item.typeOfJob}
                                    />
                                    <label htmlFor="typeOfJob">{lang[appLanguage].expWorkType}</label>
                                  </div>
                                </div>
                              </div>
                              <hr />
                              <div className="row">
                                <div className="col-12 col-md-6 my-1">
                                  <div className="input-group">
                                    <span className="input-group-text text-muted">{item.currency}</span>
                                    <div className="form-floating">
                                      <input
                                        disabled
                                        readOnly
                                        type="text"
                                        className="form-control"
                                        id="startingSalary"
                                        value={item.startingSalary}
                                      />
                                      <label htmlFor="startingSalary">{lang[appLanguage].expSlryStrt}</label>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12 col-md-6 my-1">
                                  <div className="input-group">
                                    <span className="input-group-text text-muted">{item.currency}</span>
                                    <div className="form-floating">
                                      <input
                                        disabled
                                        readOnly
                                        type="text"
                                        className="form-control"
                                        id="endingSalary"
                                        value={item.endingSalary}
                                      />
                                      <label htmlFor="endingSalary">{lang[appLanguage].expSlryEnd}</label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <hr />
                              <div className="row">
                                <div className="col-12 col-md-6 my-1">
                                  <div className="form-floating">
                                    <input
                                      disabled
                                      readOnly
                                      type="text"
                                      className="form-control"
                                      id="managerName"
                                      value={item.managerName}
                                    />
                                    <label htmlFor="managerName">{lang[appLanguage].expMngrName}</label>
                                  </div>
                                </div>
                                <div className="col-12 col-md-6 my-1">
                                  <div className="form-floating">
                                    <input
                                      disabled
                                      readOnly
                                      type="text"
                                      className="form-control"
                                      id="managerTitle"
                                      value={item.managerTitle}
                                    />
                                    <label htmlFor="managerTitle">{lang[appLanguage].expMngrTitle}</label>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-12 col-md-6 my-1">
                                  <div className="form-floating">
                                    <input
                                      disabled
                                      readOnly
                                      type="text"
                                      className="form-control"
                                      id="managerNumber"
                                      value={item.managerNumber}
                                    />
                                    <label htmlFor="managerNumber">{lang[appLanguage].expMngrPhon}</label>
                                  </div>
                                </div>
                                <div className="col-12 col-md-6 my-1">
                                  <div className="form-floating">
                                    <input
                                      disabled
                                      readOnly
                                      type="text"
                                      className="form-control"
                                      id="performanceRating"
                                      value={item.performanceRating}
                                    />
                                    <label htmlFor="performanceRating">{lang[appLanguage].expMngrRate}</label>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-12 col-md-6 my-1">
                                  <div className="form-floating">
                                    <input
                                      disabled
                                      readOnly
                                      type="text"
                                      className="form-control"
                                      id="arrangeCall"
                                      value={item.arrangeCall ? "✅" : "❎ "}
                                    />
                                    <label htmlFor="arrangeCall">{lang[appLanguage].expArngCall}</label>
                                  </div>
                                </div>
                                <div className="col-12 col-md-6 my-1">
                                  <div className="form-floating">
                                    <input
                                      disabled
                                      readOnly
                                      type="text"
                                      className="form-control"
                                      id="leaveReason"
                                      value={item.leaveReason}
                                    />
                                    <label htmlFor="leaveReason">{lang[appLanguage].expLevRsn}</label>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-12 col-md-6 my-1">
                                  <div className="form-floating">
                                    <textarea
                                      disabled
                                      readOnly
                                      type="text"
                                      className="form-control"
                                      id="leaveExplanation"
                                      style={{ height: 100 }}
                                      value={item.leaveExplanation}
                                    />
                                    <label htmlFor="leaveExplanation">{lang[appLanguage].expLevExpln}</label>
                                  </div>
                                </div>
                                <div className="col-12 col-md-6 my-1">
                                  <div className="form-floating">
                                    <textarea
                                      disabled
                                      readOnly
                                      type="text"
                                      className="form-control"
                                      id="mostLiked"
                                      style={{ height: 100 }}
                                      value={item.mostLiked}
                                    />
                                    <label htmlFor="mostLiked">{lang[appLanguage].expMostLike}</label>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-12 col-md-6 my-1">
                                  <div className="form-floating">
                                    <textarea
                                      disabled
                                      readOnly
                                      type="text"
                                      className="form-control"
                                      id="leastLiked"
                                      style={{ height: 100 }}
                                      value={item.leastLiked}
                                    />
                                    <label htmlFor="leastLiked">{lang[appLanguage].expLstEnjy}</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </StrictModeDroppable>
          </DragDropContext>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 mt-2">
            <button type="button" className="btn btn-success button me-1" onClick={prevStep} disabled={showForm}>
              {lang[appLanguage].btnBack}
            </button>
            <button
              type="button"
              disabled={experienceHistory.length === 0 || showForm}
              className={`btn ${experienceHistory.length === 0 ? "btn-dark" : "btn-success"} button`}
              data-bs-toggle="modal"
              data-bs-target="#staticBackdrop"
            >
              {loading ? (
                <>
                  <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
                  <span className="px-1 status" role="status">
                    {lang[appLanguage].loading}
                  </span>
                </>
              ) : (
                lang[appLanguage].btnSaveCont
              )}
            </button>
          </div>
        </div>
      </form>
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                {lang[appLanguage].expModlTitle}
              </h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body fw-semibold">
              <p>{lang[appLanguage].expModlDisc1}</p>
              <p>{lang[appLanguage].expModlDisc2}</p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                {lang[appLanguage].btnClose}
              </button>
              <button
                type="button"
                onClick={() => {
                  setLoading(true);
                  handleSaveAndContinue();
                }}
                className="btn btn-primary"
                data-bs-dismiss="modal"
              >
                {loading ? (
                  <>
                    <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
                    <span className="px-1 status" role="status">
                      {lang[appLanguage].loading}
                    </span>
                  </>
                ) : (
                  lang[appLanguage].btnModlUndstand
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Experience.propTypes = {
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
  experienceHistory: PropTypes.array.isRequired,
  handleDataChange: PropTypes.func.isRequired,
};

export default Experience;
