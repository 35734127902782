import React, { createContext, useState } from "react";

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [appLanguage, setAppLanguage] = useState("en");

  const toggleLanguage = (lang) => {
    setAppLanguage(lang);
  };

  return (
    <LanguageContext.Provider value={{ appLanguage, setAppLanguage: toggleLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};
