const translations = {
  en: {
    // Common alerts and texts
    errorTitle: "Error!",
    errorLoadingJob: "Error loading job description.",
    errorAccessToken: "Error refreshing access token. Please try again later.",
    errorSubmittion: "Error submitting data. Please refresh or try again later.",

    warnNoJob: "No job found.",
    warnOfferExpired: "Unfortunately the job offer is expired or the position has been filled.",
    warnAlreadyAplied: "You have already applied for this job.",
    warnVerifyEmail: "Please verify your email to access the application form.",
    warnWaitTitle: "Wait!",
    warnRmvAllHstry: "Kindly remove all your history before unchecking",
    warnCnfrmRefCall: "You must confirm the reference call arrangement to continue.",

    successTitle: "Success!",
    successSubmittion: "Submission successful",
    successExpAdded: "Experience Added!",
    successMorExpBetr: "The more experience you add, the better your chances are of being shortlisted.",

    valBlankField: "Field can't be blank.",
    valPhone: "Phone number is required and must be in the correct format.",

    loading: "Loading...",

    selectOpt: "Select...",

    btnClose: "Close",

    // Job description
    congrats:
      "Congratulations! You've successfully applied for this exciting position. We're cheering for your success and wish you the best of luck on this amazing journey!",
    jdPostion: "Position",
    jdExpLevel: "Experience Level",
    jdDept: "Department",
    jdLocation: "Location",
    jdWorkType: "Work Type",
    btnApply: "Apply",

    posApplying: "POSITION YOU ARE APPLYING FOR",
    viewJobDesc: "View Job Description",

    // Personal Information
    piValFullname: "Full Name must be between 13 and 50 characters.",
    piValDoB: "Date of Birth can't be in the future.",
    piValPoB: "Place of Birth must be between 3 and 50 characters.",
    piValChildNum: "Number of Children must be between 0 and 15.",
    piValNationality: "Nationality must be between 3 and 20 characters.",
    piValReligion: "Religion must be between 3 and 15 characters.",
    piValAddress: "Current Address must be between 10 and 55 characters.",
    piValLinkedIn: "Please enter a valid LinkedIn URL starting with https://linkedin.com/",

    piDesc1:
      "Click the Save & Continue button to save your work and move to the next section, or click the Save button if you need to log out and return at a later time.",
    piDesc2: "Do not use the back button in your browser. Just click the section title to review or edit.",
    piDesc3: "For best security practices, please logout after you are finished.",
    piTitle: "Personal Information",
    piEmail: "Email",
    piFullname: "Full Name & Surname",
    piGender: "Gender",
    piDoB: "Date of Birth",
    piGenMale: "Male",
    piGenFemale: "Female",
    piPoB: "Place of Birth (City, Country)",
    piMStatus: "Marital Status",
    piMstatSingle: "Single",
    piMstatEngaged: "Engaged",
    piMstatMarried: "Married",
    piMstatDivorced: "Divorced / Separated",
    piChildNum: "Number of Children",
    piNationality: "Nationality",
    piReligion: "Religion",
    piAddress: "Current Address (Street, City, Country)",
    piPhone: "Phone Number",
    piLinkedIn: "LinkedIn URL",

    // Education
    eduValGradYear: "Year of Graduation should be between 1900 and ",
    eduValCertLvl: "Please select a Certificate Level",
    eduValGPA1: "Must be between 1 and 100!",
    eduValGPA2: "Must not be 0 or empty!",

    eduDesc1:
      "Please provide detailed and complete education history in the designated spaces, starting with the most recent education.",
    eduTitle: "Education Background",
    btnAddEduHistry: "Add New Education History",
    eduHaveEduHistry: "Do you have Education History?",
    eduName: "School or University Name",
    eduGradYear: "Year of Graduation",
    eduCertLvl: "Certificate Level",
    eduCrtLvlHS: "High school",
    eduCrtLvlDip: "Diploma",
    eduCrtLvlBsc: "Bachelor's",
    eduCrtLvlMst: "Master",
    eduCrtLvlPhD: "PhD",
    eduMajor: "Major",
    eduGPA: "GPA Grade",
    eduCountry: "Country",
    eduStdyLang: "Language of Study",

    // Experience
    expValStrtDate: "Start Date should not be in the future",
    expValPrfRate: "Please select an option!",
    expValStrtSlry: "Starting Salary must be between 1 and 999999!",
    expValLevResn: "Please select a Reason for Leaving!",
    expValLevExpln: "Please write a brief explanation of the leave reason!",

    expDisc1:
      "Please provide detailed and complete for each job information in the designated spaces, starting with the most recent experience.",
    expDisc2: "Kindly make sure to fill all your Experience History!",
    expDisc3:
      "If you have only one job that you've been working at for many years, list each of your position titles that have been raised over time as separate experiences.",
    expDisc4: "The more experience history you fill out, the better your chances of being shortlisted.",
    expTitle: "Experience Background",
    btnAddExpHitry: "Add new Experience history",
    expCpnyName: "Company Name",
    expCpnyAdrs: "Company Addres",
    expWorkField: "Field of Work",
    expPosTitle: "Position Title",
    expWorkType: "Type of Work",
    expStrtDate: "Start Date",
    expEndDate: "End Date",
    expCrntlyWork: "I'm still currently working here",
    expSlryStrt: "Starting Base Salary in Dollar",
    expSlryEnd: "Ending Base Salary in Dollar",
    expMngrName: "Manager Name",
    expMngrTitle: "Manager Title",
    expMngrPhon: "Manager Phone Number",
    expArngCall: "Can you arrange a call with this manager?",
    expOptionYes: "Yes",
    expOptionNo: "No",
    expMngrRate: "How would your manager rate your performance?",
    expMostLike: "What did you like most about your job?",
    expLstEnjy: "What did you least enjoy?",
    expLevRsn: "Reason for Leaving",
    expLevExpln: "Explanation for Reason(s) for Leaving",
    expNoteRefCal:
      "Note: A final step in the hiring process is for candidates to arrange reference calls with Managers. This will only be done when candidates are willing to arrange these calls (usually when a job offer is being discussed).",
    expModlTitle: "Confirm Action",
    expModlDisc1: "Make sure you fill out all of your detailed experience history!",
    expModlDisc2: "Remember, the more experience you add, the better your chances are of being shortlisted.",
    btnModlUndstand: "Understood",

    // Language Skills and Attachment
    errorFileInvild: "Invalid file type. Only PDF, image, and DOC files are allowed.",
    errorFileSize: "File size exceeds the limit of 5 MB.",
    errorAddLangTitle: "Error in Language Skills!",
    errorAddLangNameEmpty:
      "One or more language names is not set, please write the language name 'ex: Arabic or English'",
    errorPiNotFond: "Personal info document not found",
    errorUpldFile: "Error uploading files: ",
    errorDltFile: "Error deleting file link: ",

    warnFixErrs: "Please fix the errors before submitting.",
    warnAddLangTitle: "Add a language!",
    warmAddOneLang: "Please add at least one language skill.",
    warmAddOneAtch: "Please upload at least one attachment.",
    warmValLangName: "Please write a language and a valid level.",
    warmLangEmpty: "Language name cannot be empty!",

    lsaDisc1:
      "Please provide your language skills, add a new language by clicking on 'Add Language' and then replace 'ex: Write a Language' with the languge that you want. After that to save click on the green save icon.",
    lsaDisc2: "Please provide your CV, click on 'Choose File' and pick your CV, it can be PDF, Word or an Image.",
    lsaDisc3: "When you finish, double check your information and click on 'Save & Continue'.",
    btnAddLang: "Add Language",
    lsaLangAction: "Actions",
    lsaLang: "Language",
    lsaLangRead: "Reading",
    lsaLangWrit: "Writing",
    lsaLangSpek: "Speaking",
    lsaLangLstn: "Listening",
    lsaAtchTitle: "Attachments",
    lsaUpldCV: "Upload your CV",
    lsaUploading: "Uploading...",

    // Summary
    errorIdNotFound: "Vacancy Id not found! Kindly go to the Job Description page and refresh",

    smryTitle: "Summary",
    smryPiTitle: "Personal Info",
    smryPiFullname: "Full Name",
    smryPiAddress: "Current Address",
    smryEduTitle: "Education History",
    smryExpTitle: "Experience History",
    smryExpStrtDate: "Start Date",
    smryExpEndDate: "End Date",
    smryExpSlryStrt: "Starting Salary",
    smryExpSlryEnd: "Ending Salary",
    smryExpArngCall: "Can Arrange Call",
    smryExpMngrRate: "Manager's Rating",
    smryExpMostLike: "What You Liked Most",
    smryExpLstEnjy: "What You Liked Least",
    smryExpLevExpln: "Explanation for Reason",
    smryLngTitle: "Language Skills",
    smryAtchTitle: "Attachment",
    btnViewCV: "View CV",
    btnViewCovrLtr: "View Cover Letter",
    btnSubmit: "Submit",

    // Common Buttons
    btnSaveCont: "Save & Continue",
    btnUpdate: "Update",
    btnAdd: "Add",
    btnCancel: "Cancel",
    btnBack: "Back",
    btnContinue: "Continue",
  },
  ar: {
    // Common alerts and texts
    errorTitle: "خطأ!",
    errorLoadingJob: "خطأ في تحميل وصف الوظيفة.",
    errorAccessToken: "حدث خطأ أثناء تحديث رمز الوصول. يرجى المحاولة مرة أخرى لاحقًا.",
    errorSubmittion: "حدث خطأ أثناء إرسال البيانات. يرجى التحديث أو المحاولة مرة أخرى لاحقًا.",

    warnNoJob: "لم يتم العثور على وظيفة.",
    warnOfferExpired: "للأسف، انتهت صلاحية عرض الوظيفة أو تم شغل المنصب.",
    warnAlreadyAplied: "لقد قمت بالتقدم لهذه الوظيفة بالفعل.",
    warnVerifyEmail: "يرجى التحقق من بريدك الإلكتروني للوصول إلى استمارة الطلب.",
    warnWaitTitle: "انتظر!",
    warnRmvAllHstry: "يرجى إزالة كل الخلفية التعليمية قبل إلغاء تحديد",
    warnCnfrmRefCall: "يجب عليك تأكيد ترتيب المكالمة المرجعية للمتابعة.",

    successTitle: "نجاح!",
    successSubmittion: "تم التقديم بنجاح",
    successExpAdded: "تمت إضافة الخبرة!",
    successMorExpBetr: "كلما أضفت المزيد من الخبرة، زادت فرصك في الانضمام إلى القائمة المختصرة.",

    valBlankField: "لا يمكن ترك الحقل فارغًا.",
    valPhone: "رقم الهاتف مطلوب ويجب أن يكون بالتنسيق الصحيح.",

    loading: "جار التحميل...",

    selectOpt: "اختر...",

    btnClose: "إغلاق",

    // Job description
    congrats: "تهانينا! لقد تقدمت بنجاح لهذا المنصب. نحن نشجعك على نجاحك ونتمنى لك كل التوفيق في هذه الرحلة الرائعة!",
    jdPostion: "عنوان",
    jdExpLevel: "مستوى الخبرة",
    jdDept: "قسم",
    jdLocation: "موقع",
    jdWorkType: "نوع العمل",
    btnApply: "تقديم",

    posApplying: "الوظيفة التي تتقدم لها",
    viewJobDesc: "عرض وصف الوظيفة",

    // Personal Information
    piValFullname: "يجب أن يكون الاسم الكامل بين 13 و 50 حرفًا.",
    piValDoB: "لا يمكن أن يكون تاريخ الميلاد في المستقبل.",
    piValPoB: "يجب أن يكون مكان الميلاد بين 3 إلى 50 حرفًا.",
    piValChildNum: "يجب أن يكون عدد الأطفال بين 0 و 15.",
    piValNationality: "يجب أن تكون الجنسية بين 3 إلى 20 حرفًا.",
    piValReligion: "يجب أن يكون الدين بين 3 إلى 15 حرفًا.",
    piValAddress: "يجب أن يكون العنوان الحالي بين 10 و 55 حرفًا.",
    piValLinkedIn: "الرجاء إدخال رابط لينكدإن صالح يبدأ بـ https://linkedin.com",

    piDesc1:
      "انقر فوق زر حفظ ومتابعة لحفظ عملك والانتقال إلى القسم التالي، أو انقر فوق زر حفظ إذا كنت بحاجة إلى تسجيل الخروج والعودة في وقت لاحق.",
    piDesc2: "لا تستخدم زر الرجوع في متصفحك. فقط انقر فوق عنوان القسم لمراجعته أو تعديله.",
    piDesc3: "لأفضل ممارسات الأمان، يرجى تسجيل الخروج بعد الانتهاء.",
    piTitle: "المعلومات الشخصية",
    piEmail: "البريد الألكتروني",
    piFullname: "الاسم الكامل واللقب",
    piGender: "جنس",
    piGenMale: "ذكر",
    piGenFemale: "أنثى",
    piDoB: "تاريخ الميلاد",
    piPoB: "مكان الميلاد (المدينة، الدولة)",
    piMStatus: "الحالة الاجتماعية",
    piMstatSingle: "أعزب",
    piMstatEngaged: "خاطب",
    piMstatMarried: "متزوج",
    piMstatDivorced: "منفصل",
    piChildNum: "عدد الاطفال",
    piNationality: "الجنسية",
    piReligion: "دين",
    piAddress: "العنوان الحالي (الشارع، المدينة، الدولة)",
    piPhone: "رقم الهاتف",
    piLinkedIn: "رابط لينكدإن",

    // Education
    eduValGradYear: "يجب أن تكون سنة التخرج بين 1900 و ",
    eduValCertLvl: "الرجاء تحديد مستوى الشهادة",
    eduValGPA1: "يجب أن يكون بين 1 و 100!",
    eduValGPA2: "لا يجب أن يكون 0 أو فارغًا!",

    eduDesc1: "يرجى تقديم الخلفية التعليمية مفصلةً وكاملة في الأماكن المخصصة، بدءًا من التعليم الأحدث.",
    eduTitle: "الخلفية التعليمية",
    btnAddEduHistry: "إضافة خلفية تعليمية",
    eduHaveEduHistry: "هل لديك خلفية تعليمية؟",
    eduName: "اسم المدرسة أو الجامعة",
    eduGradYear: "سنة التخرج",
    eduCertLvl: "مستوى الشهادة",
    eduCrtLvlHS: "مدرسة ثانوية",
    eduCrtLvlDip: "دبلوم",
    eduCrtLvlBsc: "بكالوريوس",
    eduCrtLvlMst: "ماجستير",
    eduCrtLvlPhD: "دكتوراه",
    eduMajor: "التخصص",
    eduGPA: "درجة الGPA",
    eduCountry: "دولة",
    eduStdyLang: "لغة الدراسة",

    // Experience
    expValStrtDate: "يجب ألا يكون تاريخ البدء في المستقبل",
    expValPrfRate: "يرجى اختيار خيار!",
    expValStrtSlry: "يجب أن يكون الراتب المبدئي بين 1 و 999999!",
    expValLevResn: "يرجى اختيار سبب للمغادرة!",
    expValLevExpln: "يرجى كتابة شرح موجز لسبب المغادرة!",

    expDisc1: "يرجى تقديم معلومات مفصلة وكاملة لكل وظيفة في الأماكن المخصصة، بدءًا من أحدث تجربة.",
    expDisc2: "يرجى التأكد من ملء تاريخ الخبرة الخاص بك بالكامل!",
    expDisc3:
      "إذا كان لديك وظيفة واحدة فقط عملت فيها لسنوات عديدة، فقم بإدراج كل منصب وظيفي تمت ترقيتك إليه بمرور الوقت كخبرات منفصلة.",
    expDisc4: "كلما زاد تاريخ الخبرة الذي تملأه، زادت فرصك في أن يتم اختيارك في قائمة أفضل المُرشّحين.",
    expTitle: "خلفية الخبرة",
    btnAddExpHitry: "إضافة تاريخ خبرة جديد",
    expCpnyName: "اسم الشركة",
    expCpnyAdrs: "عنوان الشركة",
    expWorkField: "مجال العمل",
    expPosTitle: "المسمى الوظيفي",
    expWorkType: "نوع العمل",
    expStrtDate: "تاريخ البدء",
    expEndDate: "تاريخ الانتهاء",
    expCrntlyWork: "ما زلت أعمل هنا حاليًا",
    expSlryStrt: "الراتب الأساسي المبدئي بالدولار",
    expSlryEnd: "الراتب الأساسي النهائي بالدولار",
    expMngrName: "اسم المدير",
    expMngrTitle: "عنوان المدير",
    expMngrPhon: "رقم هاتف المدير",
    expArngCall: "هل يمكنك ترتيب مكالمة مع هذا المدير؟",
    expOptionYes: "نعم",
    expOptionNo: "لا",
    expMngrRate: "كيف سيقيم مديرك أدائك؟",
    expMostLike: "ما الذي أعجبك أكثر في عملك؟",
    expLstEnjy: "ما الذي لم تستمتع به على الأقل في عملك؟",
    expLevRsn: "سبب المغادرة",
    expLevExpln: "شرح لسبب (أسباب) المغادرة",
    expNoteRefCal:
      "ملاحظة: الخطوة الأخيرة في عملية التوظيف هي ترتيب مكالمات مرجعية مع المديرين. سيتم ذلك فقط عندما يكون المرشحون على استعداد لترتيب هذه المكالمات (عادةً عندما يتم مناقشة عرض العمل).",
    expModlTitle: "تأكيد الإجراء",
    expModlDisc1: "تأكد من ملء تاريخ الخبرة التفصيلي بالكامل!",
    expModlDisc2: "تذكر، كلما زادت الخبرة التي تضيفها، زادت فرصك في أن يتم اختيارك في قائمة أفضل المُرشّحين.",
    btnModlUndstand: "فهمت",

    // Language Skills and Attachment
    errorFileInvild: "نوع الملف غير صالح. يسمح فقط بملفات PDF، الصور، وDOC.",
    errorFileSize: "حجم الملف يتجاوز الحد الأقصى المسموح به وهو 5MB.",
    errorAddLangTitle: "خطأ في المهارات اللغوية!",
    errorAddLangNameEmpty: "اسم لغة واحدة أو أكثر غير محدد، يرجى كتابة اسم اللغة مثل 'Arabic أو English'",
    errorPiNotFond: "لم يتم العثور على مستند المعلومات الشخصية",
    errorUpldFile: "خطأ في تحميل الملفات: ",
    errorDltFile: "خطأ في حذف رابط الملف: ",

    warnFixErrs: "يرجى إصلاح الأخطاء قبل التقديم.",
    warnAddLangTitle: "أضف لغة!",
    warmAddOneLang: "يرجى إضافة مهارة لغوية واحدة على الأقل.",
    warmAddOneAtch: "يرجى تحميل مرفق واحد على الأقل.",
    warmValLangName: "يرجى كتابة لغة ومستوى صالح.",
    warmLangEmpty: "اسم اللغة لا يمكن أن يكون فارغًا!",

    lsaDisc1:
      "يرجى تقديم مهاراتك اللغوية، أضف لغة جديدة بالضغط على 'إضافة لغة' ثم استبدل 'Write a Language' باللغة التي ترغب فيها. بعد ذلك أحفظ التغييرات اضغط على أيقونة الحفظ الخضراء.",
    lsaDisc2:
      "يرجى تقديم سيرتك الذاتية، اضغط على 'اختر ملف' واختر سيرتك الذاتية، يمكن أن يكون الملف PDF، Word أو صورة.",
    lsaDisc3: "عندما تنتهي، تحقق من معلوماتك مرة أخرى ثم اضغط على 'حفظ ومتابعة'.",
    lsaLangTitle: "المهارات اللغوية",
    btnAddLang: "إضافة لغة",
    lsaLangAction: "الإجراءات",
    lsaLang: "اللغة",
    lsaLangRead: "القراءة",
    lsaLangWrit: "الكتابة",
    lsaLangSpek: "التحدث",
    lsaLangLstn: "الاستماع",
    lsaAtchTitle: "المرفقات",
    lsaUpldCV: "ارفق سيرتك الذاتية",
    lsaUploading: "جاري الارفاق...",

    // Summary
    errorIdNotFound: "لم يتم العثور على معرف (id) الوظيفة! يرجى الانتقال إلى صفحة وصف الوظيفة وتحديثها",

    smryTitle: "الملخص",
    smryPiTitle: "المعلومات الشخصية",
    smryPiFullname: "الاسم الكامل",
    smryPiAddress: "العنوان الحالي",
    smryEduTitle: "السجل التعليمي",
    smryExpTitle: "سجل الخبرات",
    smryExpStrtDate: "تاريخ البدء",
    smryExpEndDate: "تاريخ الانتهاء",
    smryExpSlryStrt: "الراتب عند البدء",
    smryExpSlryEnd: "الراتب عند الانتهاء",
    smryExpArngCall: "هل يمكن ترتيب مكالمة",
    smryExpMngrRate: "تقييم المدير",
    smryExpMostLike: "ما أعجبك أكثر",
    smryExpLstEnjy: "ما أعجبك أقل",
    smryExpLevExpln: "تفسير السبب",
    smryLngTitle: "المهارات اللغوية",
    smryAtchTitle: "المرفقات",
    btnViewCV: "عرض السيرة الذاتية",
    btnViewCovrLtr: "عرض Cover Letter",
    btnSubmit: "إرسال",

    // Common Buttons
    btnSaveCont: "حفظ ومتابعة",
    btnUpdate: "تحديث",
    btnAdd: "إضافة",
    btnCancel: "إلغاء",
    btnBack: "الرجوع",
    btnContinue: "استمر",
  },
};
export default translations;
