import React, { useState, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { signOut } from "firebase/auth";
import AuthModal from "../../Auth/AuthModal/AuthModal";
import { auth } from "../../firebase";
import { LanguageContext } from "../../context/LanguageContext";
import "./Navbar.css";

const Navbar = ({ user }) => {
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const id = params.has("id") ? params.get("id") : localStorage.getItem("id");
  const { appLanguage, setAppLanguage } = useContext(LanguageContext);

  if (localStorage.getItem("lang") == null || localStorage.getItem("lang") === "en") {
    setAppLanguage("en");
  } else {
    setAppLanguage("ar");
  }

  const handleLogout = () => {
    signOut(auth);
    localStorage.setItem("id", id);
    if (params.has("id") || params.has("wizard")) {
      window.location.reload();
    } else {
      const id = localStorage.getItem("id");
      window.location.href = `/?id=${id}`;
    }
  };

  const handleAppLanguageChange = (e) => {
    setAppLanguage(e.target.value);
    localStorage.setItem("lang", e.target.value);
  };

  return (
    <>
      <nav className="row navbar navbar-logo mx-0">
        <Link to={`/?id=${id}`} className="align-items-end my-1 col text-decoration-none d-flex">
          <img src={`${process.env.PUBLIC_URL}/Saaeda_Logo.png`} alt="Logo" />
          <h1 className="display-6 fs-4 text-white">Welcome to Saaeda Career System - SCS</h1>
        </Link>
        <div className="col col-6 my-1">
          <div className="float-end language-btn me-3">
            <select value={appLanguage} onChange={handleAppLanguageChange} className="form-select">
              <option value="en">English</option>
              <option value="ar">Arabic</option>
            </select>
          </div>
        </div>
        <div className="col col-md-auto my-1">
          {user ? (
            <div className="float-end profile-section">
              <div className="dropdown dropstart d-flex">
                <span className="align-content-center dropdown-toggle pe-1" data-bs-toggle="dropdown"></span>

                {user.photoURL ? (
                  <img
                    src={user.photoURL}
                    alt="Profile"
                    className="profile-picture dropdown-toggle"
                    data-bs-toggle="dropdown"
                  />
                ) : (
                  <i className="bi bi-person-circle fs-1" data-bs-toggle="dropdown"></i>
                )}

                <ul className="dropdown-menu">
                  <div className="dropdown-item">{user.email}</div>
                  <button className="dropdown-item" onClick={handleLogout}>
                    Logout
                  </button>
                </ul>
              </div>
            </div>
          ) : (
            <button className="float-end btn btn-light" onClick={() => setIsAuthModalOpen(true)}>
              <i className="bi bi-box-arrow-in-right pe-1"></i>
              Login
            </button>
          )}
        </div>
      </nav>
      <AuthModal isOpen={isAuthModalOpen} onRequestClose={() => setIsAuthModalOpen(false)} />

      <div
        aria-live="polite"
        aria-atomic="true"
        className="bg-body-secondary position-relative bd-example-toasts rounded-3"
      >
        <div className="toast-container p-3 position-fixed top-0 end-0" id="toastPlacement" style={{ zIndex: 9999 }}>
          <div id="liveToast" className="toast" role="alert" aria-live="assertive" aria-atomic="true">
            <div className="toast-header">
              <img
                src={`${process.env.PUBLIC_URL}/Saaeda_Logo.png`}
                className="rounded me-2 carousel-control-next-icon"
                alt="Logo"
              />
              <strong className="me-auto">Notification</strong>
              <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
            </div>
            <div className="toast-body">Hello, world! This is a toast message.</div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Navbar;
