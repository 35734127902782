// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore/lite";

const firebaseConfig = {
  apiKey: "AIzaSyDIwvTE9Cx_9Nzi48D_UeX1ojE3c8EL1X8",
  authDomain: "saaeda-97839.firebaseapp.com",
  projectId: "saaeda-97839",
  storageBucket: "saaeda-97839.appspot.com",
  messagingSenderId: "969607256796",
  appId: "1:969607256796:web:dcc3cf7e1fc8ba91513219",
  measurementId: "G-PCKWN7WZL0",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);
const googleAuthProvider = new GoogleAuthProvider();

export { db, auth, googleAuthProvider };
