/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "./Footer.css";

const Footer = () => (
  <div className="container">
    <footer className="footer footer-bg row row-cols-1 row-cols-sm-2 row-cols-md-5 py-5" dir="ltr">
      <div className="col mb-3">
        <a
          href="https://www.saaeda.com/"
          className="d-flex logo-container align-items-center mb-3 link-body-emphasis text-decoration-none"
        >
          <img src={`${process.env.PUBLIC_URL}/Saaeda_Logo.png`} width={100} alt="Logo" />
        </a>
        <p className="text-body-secondary logo-container">
          © 2024&nbsp;
          <a className="p-0 text-black text-decoration-underline" href="https://www.saaeda.com/">
            Saaeda
          </a>
        </p>
      </div>

      <div className="col mb-3"></div>

      <div className="col mb-3">
        <h5>Contact Information</h5>
        <ul className="nav flex-column">
          <li className="nav-item mb-2">
            Phone number
            <ul className="nested-list">
              <li>
                <a href="tel:+9647707007727" className="text-black">
                  +964 770 700 7727
                </a>
              </li>{" "}
              <li>
                <a href="tel:+9647707007737" className="text-black">
                  +964 770 700 7737
                </a>
              </li>
            </ul>
          </li>
          <li className="nav-item mb-2">
            Email:{" "}
            <a href="mailto:info@saaeda.com" className="text-black text-decoration-underline">
              info@saaeda.com
            </a>
          </li>
        </ul>
      </div>

      <div className="col col-xxl-auto">
        <h5>Address</h5>
        <ul className="nav flex-column">
          <li className="nav-item mb-2">Erbil branch: Aram Village - Villa 573</li>
          <li className="nav-item mb-2">Baghdad branch: Alharthya</li>
          <li className="nav-item mb-2">Sulaymaniyah: 60m, Goizha Apartments</li>
        </ul>
      </div>
    </footer>
  </div>
);

export default Footer;
